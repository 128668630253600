
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { Route, useNavigate, useParams } from '@tanstack/react-router';
import { useGetEvaluationprogress, useGetProjectsProjectId } from '../../../openapi/api/endpoints/default/default';
import WorkspaceComponent from "@/components/Workspace/WorkspaceComponent";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/store/track-context";
import { Button } from "@/components/ui/button";
import StartAlgoText from "./CreateProject/components/StartEvalTexts/StartAlgoText";
import StartProjectText from "./CreateProject/components/StartEvalTexts/StartProjectText";
//import HeaderComponent from "@/components/Header/Header";


const StartEvaluation = () => {
  const {courseId} = useParams({from:'/'});
  const {isLoading} = useGetProjectsProjectId(courseId)
  const {track} = useContext(AuthContext)
  const navigate = useNavigate({from:'/'})
  const currentProject = track?.trackProgression.find((trackProgressionItem)=>trackProgressionItem.projectID===courseId)
  const {data} = useGetEvaluationprogress({projectId:courseId,skill:currentProject?.skill??''},{
    query: {
      enabled: !!(courseId&&currentProject?.skill)
    }
  })
  let evalType: string
    if((data?.type=='project')||(data?.type=='algo')){
        evalType = data.type
    }else{
        evalType = 'QA'
    }

  useEffect(()=>{
    if(!track){
      navigate({to:'/home'})
    }
  },[track, navigate])
  
  if(isLoading){
    return(
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        ...Loading
      </div>
    )
  }

  const onStartEval = () => {
    navigate({
      to:'/course/$courseId/$templateId/$skill/$type',
      params:{
          courseId:courseId,
          templateId:currentProject?.templateId,
          skill:currentProject?.skill??'',
          type:evalType
        }
    })
  }

  
  
  
  return (
    <div className="flex flex-col justify-center items-center p-4 bg-gray-100 min-h-screen space-y-8 max-md:w-screen overflow-x-auto">
      
      <div className="flex flex-col h-full justify-center items-center space-y-8">

        {(evalType=='project') ? 
        <div className="flex flex-col h-full items-center justify-center space-y-8">
          <StartProjectText/>
          <WorkspaceComponent projectId={courseId}/>
        </div>
        :
        <div className="flex flex-col h-full items-center justify-center space-y-8">
          <StartAlgoText/>
          <Button onClick={onStartEval} className="rounded-full bg-teal100 hover:bg-blue100">Start</Button>
        </div>}

      </div>
    </div>
    
  )
}

const StartEvaluationRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "course/$courseId",
    component: StartEvaluation,
    stringifyParams: ({ courseId }) => ({ courseId: `${courseId}` }),
})

export default StartEvaluationRoute;
