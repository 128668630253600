/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainAppRoute } from '@/rootRoutes/MainApp'
import { Route } from '@tanstack/react-router'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import ChatWindow from '@/components/Chat/ChatWindow'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import HeaderComponent from '@/components/Header/Header'
import useChat from '@/utils/Websocket/useChat'


const ClientChat=()=>{
  const {register, handleSubmit, setValue}=useForm()
  const onSendMessage=()=>{
    setValue('ClientMessage','')
  }
  const {currentMessages,sendMessage}=useChat({onSendMessage})


  
  return (
    <div className=" flex flex-auto  justify-center items-center h-screen py-4 bg-gray-800 pl-4 pr-2">
      <div className="flex flex-col basis-full h-full bg-gray-800"> 
         <HeaderComponent title='Client Chat'/>
        <div className="basis-10/12 p-1 overflow-auto">
            <ChatWindow 
            conversation={currentMessages}
            />
        </div>
        <div className="flex basis-1/12 p-1 bg-gray-800">
            <form className="flex grow w-full" onSubmit={handleSubmit(sendMessage)}>
                <Input 
                id="message" 
                placeholder="Send a message..." 
                className=" mr-2 text-white bg-gray-600 border-none"
                {...register('ClientMessage')}/>
                <Button type='submit'><SendRoundedIcon/></Button>
            </form>
        </div>
      </div>
    </div>
  )
}


const ClientChatRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "chat",
    component: ClientChat,//this is how the component is rendered based on the path
    //this is why the suspense is working
})

export default ClientChatRoute;
