/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import evaluateCode from "./evaluateCode";
import { TestCases } from './contexts';


declare global {
    interface Window {
        loadPyodide?: any;
    }
  }
  
export const usePython = ({tests}:{tests:TestCases[]}) => {
    const [output, setOutput] = useState<string>('');
    const [logs, setLogs] = useState<string[]>([]);
    const [pyodide, setPyodide] = useState<any>(null);

    useEffect(() => {
        // Load Pyodide when the component mounts
        window.loadPyodide().then((pyodideInstance:any) => {
            setPyodide(pyodideInstance);
        });
    }, []);
    // Override console.log to capture logs
    useEffect(() => {
        const originalLog = console.log;
        console.log = (message, ...optionalParams) => {
            setLogs((prevLogs) => [...prevLogs, message.toString()]);
            originalLog(message, ...optionalParams);
        };
        return () => {
            console.log = originalLog; // Restore original console.log on cleanup
        };
    }, []);
    const runPythonCode = async ({code}:{code:any}) => {
        // Initialize logs to capture printed output
        const capturedOutput:string[] = [];
        
        if (pyodide) {
            try {
                // Redirect stdout to capture printed output
                pyodide.setStdout({
                    // Custom print function to collect printed outputs
                    batched: (text:string) => {
                        capturedOutput.push(text);
                    }
                });
                
                // Prepare the Python code
                const finalCode = evaluateCode({ projectContext: tests as any, incomingCode: code });
                
                // Run the Python code
                await pyodide.runPythonAsync(finalCode);

                console.log({result: capturedOutput})
                setOutput(capturedOutput.join('\n'))


                return({result: capturedOutput.join('\n')})
                
            } catch (error) {
                throw ((error as Error).message)
            } finally {
                // Reset stdout to default
                pyodide.setStdout(console.log);
            }
        } else {
            setOutput('Pyodide is not loaded yet');
            await window.loadPyodide()
        }
    };
    return {
        logs,
        output,
        runPythonCode,
    };
};
export default usePython;













