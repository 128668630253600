import { ScrollArea } from "../ui/scroll-area"
import MessageBubble, { ChatBubbleProp } from "./MessageBubble"


/**
 * Create a chat app which
 * @one Displays messages left and right side of the box depending of if its either sender or receiver (jean)
 * @two takes an array of conversation and displays it porperly
 * h-[590px] w-[1250px]
 */
const ChatWindow=({conversation}:{conversation:ChatBubbleProp[]})=>{
    const showChat=conversation && conversation.length
    return (
        <ScrollArea className="h-full pr-4 overflow-y-auto">
            {showChat ? conversation.map(({from,text})=>{
                return <MessageBubble  from={from} text={text}/>
            }
            ) : <div></div>}
        </ScrollArea>
    )
}

ChatWindow({
    conversation:[]
})


export default ChatWindow;