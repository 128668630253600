import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route, useBlocker, useNavigate, useParams } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { WorkspaceSocket } from '@/utils/Websocket/socket';
import { AlgoEvaluation } from './Algo/AlgoEvaluation';
import { useGetProjectContext } from '../../../openapi/api/endpoints/default/default';
import { AlgoProjectContext } from '../../../openapi/api/model/algoProjectContext';


export const BeginAlgoTestPage= ()=>{
  const {templateId,skill} = useParams({from:'/'});
  //const projectID = courseId
  const navigate = useNavigate({from:'/'})
  const [isBlockerActive, setIsBlockerActive] = useState(true);
  const { data:context } = useGetProjectContext({templateId:templateId??'',skill:skill??''},{
    query:{
      enabled: !!(templateId&&skill)
    }
  })

  console.log("Context: ",context)
    
  const disableBlocker = () => {
    setIsBlockerActive(false);
    WorkspaceSocket.disconnect()
  }

  useEffect(() => {
    if (!isBlockerActive) {
      // Navigate to the overview page after blocker is disabled
      navigate({to:'/overview'});
    }
  }, [isBlockerActive, navigate]);

  useBlocker({
    blockerFn:() => {
      if (!isBlockerActive) return true;
      const confirmLeave=window.confirm('Are you sure you want to leave? This would terminate the evaluation')
      if(confirmLeave){
        WorkspaceSocket.disconnect()
      }
      return confirmLeave

    },
    condition:isBlockerActive
  })


  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // show a confirmation dialog when attempting to close browser
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Legacy way of showing a confirmation dialog
      return message; // Modern way of showing a confirmation dialog
    };

    const handleUnload = () => {
        WorkspaceSocket.disconnect();
    };
    //attach event listeners on mount
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);


  return () => {
    //get rid of event listeners on dismount
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("unload", handleUnload);
  }
  
}, [])


  return(
  <>
    <AlgoEvaluation context={context as AlgoProjectContext} skill={skill} blockerFn={disableBlocker}/>
    <Toaster/>
  </>
  )
}


const BeginAlgoTestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/$templateId/$skill/algo",
  component: BeginAlgoTestPage,
})

export default BeginAlgoTestRoute;