import { Send } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import Timer from '@/components/ui/custom/Timer'
import { useForm } from 'react-hook-form'
import useChat from '@/utils/Websocket/useChat'
import ChatWindow from '@/components/Chat/ChatWindow'

const theme = {
  mint100: '#ccfbf1',
  teal100: '#26A69A',
  blue100: '#101820',
}


const ChatComponent = ({timerDone}:{timerDone:()=>void}) => {
  const {register, handleSubmit, setValue}=useForm()
  const onSendMessage=()=>{
    setValue('ClientMessage','')
  }
  const {currentMessages,sendMessage}=useChat({onSendMessage})

  return (
        <Card className="flex-auto w-full h-full flex flex-col" style={{ backgroundColor: theme.blue100, color: theme.mint100 }}>
          <CardHeader className="flex-shrink-0 items-center">
              <CardTitle className="text-center text-white">Evaluation Chat</CardTitle>
              <Timer timerMinutes={15} timerDone={timerDone}/>
            </CardHeader>
            <CardContent className="flex-grow overflow-hidden">
                <ChatWindow conversation={currentMessages}/>
            </CardContent>
            <CardFooter className="flex-shrink-0">
                <form className="flex grow w-full" onSubmit={handleSubmit(sendMessage)}>
                    <Input
                    id="message"
                    placeholder="Send a message..."
                    className=" mr-2 text-white bg-gray-600 border-none"
                    {...register('ClientMessage')}/>
                    <Button 
                      type='submit'
                      style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                    >
                      <Send className="h-4 w-4" />
                      <span className="sr-only">Send</span>
                    </Button>
                </form>
            </CardFooter>
        </Card>
  )
}

export default ChatComponent;


/*     <Card className="flex-auto w-full h-full flex flex-col" style={{ backgroundColor: theme.blue100, color: theme.mint100 }}>
            <CardHeader className="flex-shrink-0 items-center">
              <CardTitle className="text-center text-white">Evaluation Chat {isConnected ? '(Connected)' : '(Disconnected)'}</CardTitle>
              <Timer timerMinutes={30} timerDone={timerDone}/>
            </CardHeader>
            <CardContent className="flex-grow overflow-hidden">
              <ScrollArea className="h-full pr-4 overflow-y-auto">
                {messages.map((message) => (
                  <div key={message.id} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                    <div className={`flex ${message.sender === 'user' ? 'flex-row-reverse' : 'flex-row'} items-end`}>
                      <Avatar className="w-8 h-8">
                        <AvatarFallback>{message.sender === 'user' ? data?.userName.charAt(0).toUpperCase() : 'B'}</AvatarFallback>
                        <AvatarImage src={message.sender === 'user' ? "/user-avatar.png" : "/bot-avatar.png"} />
                      </Avatar>
                      <div 
                        className={`mx-2 py-2 px-3 rounded-lg ${
                          message.sender === 'user' 
                            ? 'bg-teal100 text-blue100' 
                            : 'bg-mint100 text-blue100'
                        }`}
                        style={{ 
                          backgroundColor: message.sender === 'user' ? theme.teal100 : theme.mint100,
                          color: theme.blue100
                        }}
                      >
                        {message.sender === 'user' ? message.text : (
                          <TypewriterText text={message.text} speed={50} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div ref={endOfMessagesRef}/>
              </ScrollArea>
            </CardContent>
            <CardFooter className="flex-shrink-0">
              <form onSubmit={(e) => { e.preventDefault(); handleSend(); }} className="flex w-full items-center space-x-2">
                <Input 
                  type="text" 
                  placeholder="Type your message..." 
                  value={input} 
                  onChange={(e) => setInput(e.target.value)}
                  className="flex-grow bg-white text-black"
                  //style={{ backgroundColor: theme.mint100, color: theme.blue100 }}
                />
                <Button 
                  type="submit" 
                  size="icon"
                  style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                >
                  <Send className="h-4 w-4" />
                  <span className="sr-only">Send</span>
                </Button>
              </form>
            </CardFooter>
          </Card> */