import { useEffect, useState } from "react"

export const useTypewriter = (text: string, speed: number) => {
    const [displayText, setDisplayText] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)
  
    useEffect(() => {
        if (currentIndex < text.length) {
            const timer = setTimeout(() => {
            setDisplayText(prev => prev + text[currentIndex])
            setCurrentIndex(prev => prev + 1)
            }, speed)
    
            return () => clearTimeout(timer)
        }
    }, [text, speed, currentIndex])
  
    return displayText
}
