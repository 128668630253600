import { Route } from "@tanstack/react-router";
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { ovr } from "@/mockData/ChartData";
import Dashboard from "./Components/Dashboard";
import {
  useGetHiringresultsSearch,
  useGetTracks,
  //usePutHiringresults,
} from "../../../openapi/api/endpoints/default/default";
import { AuthContext } from "../../store/track-context";
import { useContext, useState } from "react";

import HeaderComponent from "@/components/Header/Header";
import useGetAverages from "./utils/useGetAverages";
//import TaskCompleteConfirm from "./Components/TaskCompleteConfirm";
import { ProjectResultsMacro } from "openapi/api/model/projectResultsMacro";
import { HiringResult } from "openapi/api/model/hiringResult";
//import { Toaster } from "@/components/ui/sonner"
import CircularProgress from "@mui/joy/CircularProgress";
import { toast } from "sonner";
import { Toaster } from "@/components/ui/sonner";

export type OverviewProps = {
  header: string | undefined;
  chartData: ProjectResultsMacro;
  averages: number[];
};

export const Overview = () => {
  const { setTrack, track } = useContext(AuthContext);
  const { data: tracks } = useGetTracks();
  const { data, isSuccess, isLoading, isError, error } = useGetAverages(); //should return an array
  const averages = data as HiringResult[] | undefined;

  /* const taskComplete = () => {
    if (averages) {
      mutate({
        data: {
          hiringCampaignId: track?.hiringCampaignId ?? "",
          sub: track?.sub ?? "",
          status: "Completed",
          results: averages[0].results,
        },
      });
    }
  }; */

  let content = (
    <div className="flex items-center justify-center flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
      No Hiring Campaigns added yet.
    </div>
  );
  if (isSuccess) {
    if (!averages || !averages?.length) {
      content = (
        <Dashboard
          chartData={ovr}
          performance={[0, 0, 0, 0]}
          trackId={track?._id}
        />
      );
    } else {
      console.log("averages: ", averages[0].results.performance);
      content = (
        <>
          <Dashboard
            chartData={averages[0].results?.macro}
            performance={[
              averages[0].results.performance?.overallSolutionFitness?.value ?? 0,
              averages[0].results.performance?.security?.value ?? 0,
              averages[0].results.performance?.performance?.value ?? 0,
              averages[0].results.performance?.responsiveness?.value ?? 0,
            ]}
            trackId={track?._id}
          />
          {/* <div className="flex flex-col w-1/3 self-center pt-5">
            {!!tracks && <TaskCompleteConfirm onPress={taskComplete} />}
          </div> */}
        </>
      );
    }
  }
  const { data: hcData } = useGetHiringresultsSearch({
    HR_format: "CampaignDetail",
    hiringCampaignId: track?.hiringCampaignId,
  });

  const [taskDone, setTaskDone] = useState<boolean>();
  console.log(taskDone);
  const hcResult = hcData as HiringResult;

  if (hcResult?.status === "Completed") {
    setTaskDone(true);
  }

  /* const { mutate } = usePutHiringresults({
    mutation: {
      onSuccess: () => {
        toast.success("Track Completion successful", {
          description: "The track has been successfully completed",
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
        });
        setTaskDone(true);
      },
      onError: () => {
        toast.error("Track Completion failed", {
          description:
            "Failed to complete the track, please make sure it is done and try again",
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
        });
      },
    },
  }); */

  if (isError) {
    toast.error("An error occured!", {
      description: `${error}`,
      action: {
        label: "Dismiss",
        onClick: () => {},
      },
      style: {
        background: "#dc2626",
      },
    });
  }
  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        <CircularProgress
          variant="soft"
          sx={{ "--CircularProgress-progressColor": "#26A69A" }}
        />
        ...Loading
      </div>
    );
  }
  const currTrack = tracks && tracks[0];

  if (!track) {
    setTrack(currTrack);
  }

  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
      <HeaderComponent title="Overview" />
      {!tracks ? (
        <div className="flex items-center justify-center flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
          No applications
        </div>
      ) : (
        content
      )}
      <Toaster />
    </div>
  );
};

const OverViewRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/overview",
  component: Overview,
});
export default OverViewRoute;
