import ChatComponent from "./Chat";

export const ChatEvaluation = ({theProjectID,templateId,skill,blockerFn}:{theProjectID?:string,templateId?:string,skill?:string,blockerFn:() => void}) => {
  console.log(theProjectID,templateId,skill)
  const goToOverview= () => {
    blockerFn();
  }

  return (
    <div className="flex flex-col py-2 px-32 bg-gray-100 h-[100dvh] max-md:w-screen overflow-x-auto">  
      <ChatComponent timerDone={goToOverview}/>
    </div>
  )
}


