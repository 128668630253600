import { Title } from '@/components/ui/Title';
import { Card, CardTitle, CardContent } from '@/components/ui/card';
import { useGetProjects } from '../../../../openapi/api/endpoints/default/default';
import { TrackTrackProgressionItem } from 'openapi/api/model';
//import { useNavigate } from '@tanstack/react-router';
import { useContext } from 'react';
import { AuthContext } from '@/store/track-context';
import { TimelineNavigator } from './TimelineNavigator';


const Timeline=()=>{
    //const navigate=useNavigate({from:'/'})
    const {track}=useContext(AuthContext)
    const projectsFromTrack = track?.trackProgression
    const {data}=useGetProjects()
    const startedProjects = data?.filter((proj)=>proj.trackID==track?._id)

    
    return (
    <>
        <Card className="flex flex-col basis-1/2 m-4 p-4 bg-white border-none">
            <CardTitle className="flex justify-center items-center text-white">
                <Title>Your Evaluations</Title>
            </CardTitle>
            <CardContent className="h-[290px] flex-auto flex-col px-2 py-4 bg-white overflow-auto">
                {   projectsFromTrack?.map((projectFromTrack: TrackTrackProgressionItem)=>
                        <TimelineNavigator projectFromTrack={projectFromTrack} startedProjects={startedProjects}/>
                    )
                } 
            </CardContent>
        </Card>
    </>
  );
}

export default Timeline;