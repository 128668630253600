import { BarChartDisplayer } from "@/components/ui/BarChart";
import Timeline from "./Timeline";
import ChangeVulnerability from "../utils/VulnerabilityChartData";
import { ProjectResultsMacro } from "openapi/api/model/projectResultsMacro";
import { HiringResultResultsMacro } from "openapi/api/model";
import MacroCard from "./MacroCard";
import { ovr } from "@/mockData/ChartData";
import { useGetTracksTrackId } from "../../../../openapi/api/endpoints/default/default";

export type DasboardProps = {
  chartData?: ProjectResultsMacro | HiringResultResultsMacro | undefined;
  performance: number[];
  trackId?: string;
};

const Dashboard = ({ chartData, performance, trackId }: DasboardProps) => {
  const { data: track } = useGetTracksTrackId(trackId ?? "", {
    query: {
      enabled: !!trackId,
    },
  });
  console.log("track: ", track);
  console.log("data: ", chartData);
  const securityCompliance = ChangeVulnerability(
    chartData as ProjectResultsMacro | HiringResultResultsMacro
  );
  let dataAvailability = true;
  if (chartData === ovr) {
    dataAvailability = false;
  }

  return (
    <div className="grid grid-rows-3 grid-cols-2 max-xl:grid-cols-1 max-xl:overflow-x-auto">
      <Timeline />

      <MacroCard
        title={"Overall Score"}
        description={"Project Success Rate"}
        cardContentStyle={"m-2 p-2"}
      >
        {(performance[0]==0 || performance[0]) ? (
          <h1 className="flex text-10xl">{`${parseInt((performance[0] ?? 0).toFixed())}%`}</h1>
        ) : (
          <h1 className="text-center">No data to be displayed</h1>
        )}
      </MacroCard>

      <MacroCard
        title={"Overrall Rating"}
        description={"Overrall performance"}
        cardContentStyle={"m-2 p-2"}
      >
        {dataAvailability ? (
          <BarChartDisplayer
            chart={chartData?.overallRatingData}
            labels={["Maintainability", "Reliability", "Security"]}
          />
        ) : (
          <h1 className="text-center">No data to be displayed</h1>
        )}
      </MacroCard>

      <MacroCard
        title={"Code Reliability"}
        description={"Stability and Reliability of the Codebase"}
        cardContentStyle={"m-2 p-2"}
      >
        {dataAvailability ? (
          <BarChartDisplayer
            chart={chartData?.Code_Reliability}
            labels={[
              "Blocker Violations",
              "Critical Violations",
              "Major Violations",
              "Minor Violations",
            ]}
          />
        ) : (
          <h1 className="text-center">No data to be displayed</h1>
        )}
      </MacroCard>

      <MacroCard
        title="Code Maintainability"
        description={"Maintainance ease"}
        cardContentStyle={"m-2 p-2"}
      >
        {dataAvailability ? (
          <BarChartDisplayer
            chart={chartData?.Code_Maintainability}
            labels={[
              "Code Smells",
              "Technical Debt",
              "Squale Index",
              "Duplicated Line",
            ]}
          />
        ) : (
          <h1 className="text-center">No data to be displayed</h1>
        )}
      </MacroCard>

      <MacroCard
        title={"Security Compliance"}
        description={"Security Standard Adherence Level Codebase"}
        cardContentStyle={"m-2 p-2"}
      >
        {dataAvailability ? (
          <BarChartDisplayer
            chart={securityCompliance}
            labels={["Hotspots", "Vulnerability"]}
          />
        ) : (
          <h1 className="text-center">No data to be displayed</h1>
        )}
      </MacroCard>
    </div>
  );
};

export default Dashboard;
