import Editor from '@monaco-editor/react';
import { useEffect, useRef, useState } from 'react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import React from 'react';
import usePython from '@/pages/Project/CreateProject/components/Python/utils/hooks';
import { AlgoProjectContext } from 'openapi/api/model';
import { TestCases } from '@/pages/Project/CreateProject/components/Python/utils/contexts';


const CodeEditor = ({skill,context}:{skill?:string,context:AlgoProjectContext}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editorRef = useRef<any>(null);
    const {output, runPythonCode} = usePython({tests:context.test_cases as TestCases[]})
    const [blinkCursor, setBlinkCursor] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setBlinkCursor((prev) => !prev);
      }, 530);
      return () => clearInterval(interval);
    }, []);
  

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleEditorDidMount=(editor:any)=>{
        editorRef.current = editor;
    }

    const showValue=()=>{
        runPythonCode({code:editorRef.current.getValue()})
        console.log('Output: ',output);
    }
  
    return (
        <div className='flex flex-col rounded-xl h-full'>
            <PanelGroup className="flex-auto w-full h-full flex flex-row" autoSaveId="example" direction="vertical">
                <Panel defaultSizePercentage={70}>
                    <Editor 
                        className='border rounded-xl  overflow-hidden' 
                        defaultLanguage={skill??"javascript"} 
                        theme='vs-dark'
                        onMount={handleEditorDidMount}
                        //defaultValue="// some comment" 
                    />
                </Panel>

                <PanelResizeHandle className="rounded m-1 h-2 bg-[#26A69A] hover:opacity-75 hover:h-3 transition-all duration-200 cursor-row-resize"/>

                <Panel defaultSizePercentage={30} className="flex flex-col">
                    <Button onClick={showValue} className="bg-primary100 rounded-full hover:bg-teal100 w-full">Compile</Button>
                    <Card className='bg-black h-full text-white p-2 overflow-y-auto overflow-x-auto'>
                    <ScrollArea className="h-[calc(100%-2rem)] w-full">
                <pre className="p-4 text-green-400 font-mono text-sm leading-relaxed whitespace-pre-wrap">
                  <span className="text-blue-400">user@machine:~$</span> cat output.txt
                  {'\n'}
                  {output.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      {'\n'}
                    </React.Fragment>
                  ))}
                  <span className="text-blue-400">user@machine:~$</span> 
                  {blinkCursor && <span className="animate-pulse">▊</span>}
                </pre>
              </ScrollArea> 
                    </Card>
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default CodeEditor;
