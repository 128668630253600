import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels"
import CodeEditor from "@/components/Editor/CodeEditor";
import MarkdownEditor from "@/components/Editor/MarkdownEditor";
import { AlgoProjectContext } from "openapi/api/model";

export const AlgoEvaluation = ({context,skill,blockerFn}:{context?:AlgoProjectContext,skill:string,blockerFn:() => void}) => {
  
  return (
    <div className="flex flex-col py-2 px-4 bg-gray-100 h-[100dvh] max-md:w-screen overflow-x-auto">
      <PanelGroup className="flex-auto w-full h-full flex flex-col" autoSaveId="example" direction="horizontal">
        <Panel defaultSizePercentage={50}>
          <MarkdownEditor markdown={context?.ProjectProposal} timerDone={blockerFn}/>
        </Panel>

        <PanelResizeHandle className="rounded m-1 w-2 bg-[#26A69A] hover:opacity-75 hover:w-3 transition-all duration-200 cursor-col-resize"/>

        <Panel defaultSizePercentage={50}>
          <CodeEditor skill={skill} context={context??{}}/>
        </Panel>
      </PanelGroup>
    </div>
  )
}


